import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BilalAkkaya from "../assets/img/bilal-akkaya.jpg"
import { useTranslation } from "gatsby-plugin-react-i18next"

export default function Biyografi() {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title="Biyografi" />
      <div className="biyografi-container">
        <div className="page-title">
          <img src={BilalAkkaya} alt="" />
          <div className="biyografi-title mobil">
            {t("kimdir?").toLocaleUpperCase()}
          </div>
        </div>
        <div className="biyografi-content">
          <div className="biyografi-title">
            {t("kimdir?").toLocaleUpperCase()}
          </div>
          <p>{t("bio-text")}</p>
        </div>
      </div>
    </Layout>
  )
}
